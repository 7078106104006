if (!customElements.get("vf-widget")) {
  class VFWidget extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      this.style.display = "block"; // Ensure it behaves like a div inside connectedCallback
      if (window.voiceform) {
        window.voiceform._loadWidget(this);
      } else {
        console.error("Voiceform instance is not available.");
      }
    }
  }

  customElements.define("vf-widget", VFWidget);
}
